import { ActivityIndicator } from '@troon/ui';
import { Suspense, Show } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { RichText } from '../components/rich-text';
import { createDollarFormatter } from '../modules/number-formatting';
import { createContentfulRequest } from '../routes/content/[model]/_client';

export function PriveFacilityRestrictions(props: { facility?: string }) {
	const dataTable = createAsync(async () => {
		const club = props.facility;
		if (!club) {
			return null;
		}
		return getData(club);
	});
	const dollarFormatter = createDollarFormatter();

	return (
		<Suspense fallback={<ActivityIndicator />}>
			<Show when={dataTable()?.fields}>
				{(data) => (
					<div class="flex flex-col gap-4 border-b border-neutral pb-4">
						<table class="w-full">
							<thead>
								<tr>
									<th class="rounded-tl-md border border-neutral bg-brand-700 px-3 py-2 text-white">Schedule</th>
									<th class="border border-neutral bg-brand-700 px-3 py-2 text-white">Days</th>
									<th class="border border-neutral bg-brand-700 px-3 py-2 text-white">Rate</th>
									<th class="rounded-tr-md border border-neutral bg-brand-700 px-3 py-2 text-white">Guest fee</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="border border-neutral bg-white px-3 py-2">{data().highSeasonDates}</td>
									<td class="border border-neutral bg-white px-3 py-2">{daysToRange(data().daysOfTheWeek)}</td>
									<td class="border border-neutral bg-white px-3 py-2">
										{dollarFormatter().format(data().highSeasonMemberRate ?? 80)}
									</td>
									<td class="border border-neutral bg-white px-3 py-2">
										{data().highSeasonGuestFee ? dollarFormatter().format(data().highSeasonGuestFee!) : ''}
									</td>
								</tr>

								<tr>
									<td class="border border-neutral bg-white px-3 py-2">{data().lowSeasonDateRanges}</td>
									<td class="border border-neutral bg-white px-3 py-2">{daysToRange(data().daysOfTheWeek)}</td>
									<td class="border border-neutral bg-white px-3 py-2">
										{dollarFormatter().format(data().lowSeasonMemberRate ?? 55)}
									</td>
									<td class="border border-neutral bg-white px-3 py-2">
										{data().lowSeasonGuestFee ? dollarFormatter().format(data().lowSeasonGuestFee!) : ''}
									</td>
								</tr>
							</tbody>
						</table>
						<Show when={data().restrictions}>{(restrictions) => <RichText document={restrictions()} />}</Show>
					</div>
				)}
			</Show>
		</Suspense>
	);
}

const getData = createContentfulRequest('prive-data');

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const daysToRange = (daysList: Array<string>) => {
	if (!daysList.length) {
		return '';
	}

	const dayIndexes = daysList.map((day) => days.indexOf(day)).sort((a, b) => a - b);
	const ranges = [];
	let start: number = dayIndexes[0]!;
	let prev: number = start;

	for (let i = 1; i <= dayIndexes.length; i++) {
		if (i === dayIndexes.length || dayIndexes[i] !== prev + 1) {
			const range = start === prev ? days[start] : `${days[start]} - ${days[prev]}`;
			ranges.push(range);
			start = dayIndexes[i]!;
		}
		prev = dayIndexes[i]!;
	}

	return ranges.join(', ');
};
